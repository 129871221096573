<template>
  <v-img src="@/assets/auth.png" class="fh fw">
    <v-container class="fh pa-0 ">
      <v-layout d-flex column class="fh">
        <v-flex grow class="d-flex align-center justify-center" >
          <v-card 
          
          max-width="450" 
          min-height="300"
          class="fw">
            <v-card-text>
              <v-form ref="form">
                <v-tabs-items v-model="editTab">
                  <v-tab-item>
                    <v-subheader>
                      <v-icon>mdi-account</v-icon>
                      ملفك الشخصي
                    </v-subheader>
                    <v-profile-img :value="currentUser.photoURL" size="100" label="الصورة الشخصية" class="mx-auto "
                        @change="change"
                    ></v-profile-img>
                    <v-list dense shaped>
                      <template v-for="(field, n) in fields">

                        <v-list-item dense  :key="n">
                          <v-list-item-content>
                          <v-list-item-icon>
                            <small class="grey--text text--lighten">
                              <v-icon small>{{field.icon}}</v-icon>
                              {{field.label}}
                            </small>
                          </v-list-item-icon>
                            <v-list-item-title>
                              {{currentUser[field.field]}}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action > 
                            <v-btn icon @click="edit(field.field)">

                              <v-icon>
                                mdi-circle-edit-outline
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider class="my-2" :key="n+'d'"></v-divider>
                      </template>
                    </v-list>
                    <v-btn  rounded text @click="edit('password')" small color="grey" > 
                      <v-icon small >mdi-lock-reset</v-icon>
                      <div class="pt-1 px-1 d-flex">
                      
                        أعادة تعيين كلمة المرور
                      </div>
                    </v-btn>  
                  </v-tab-item>
                  <v-tab-item>
                    <v-subheader>
                      <v-icon>mdi-label</v-icon>
                      تعديل الأسم
                      <v-spacer></v-spacer>
                      <v-btn small text color="success"  @click="update(null)" :loading="loading"> 
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn small text color="error" @click="edit(null)" :disabled="loading"> 
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-subheader>
                      <v-text-field
                          autofocus
                          rounded
                          outlined
                          dense
                          label="الأسم"
                          name="displayName"
                          prepend-inner-icon="mdi-label"
                          :value="currentUser.displayName"
                          required
                          :rules="getFieldRule('required')"
                        ></v-text-field>
                  </v-tab-item>
                  <v-tab-item>
                    <v-subheader>
                      <v-icon>mdi-email</v-icon>
                      تعديل البريد الإلكتروني
                      <v-spacer></v-spacer>
                      <v-btn small text color="success"  @click="update(null)" :loading="loading"> 
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn small text color="error" @click="edit(null)" :disabled="loading"> 
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-text-field
                          rounded
                          outlined
                          autofocus
                          dense
                          label="البريد الالكتروني"
                          name="email"
                          prepend-inner-icon="mdi-email"
                          :value="currentUser.email"
                          required
                          :rules="getFieldRule('required|email')"
                          append-icon="mdi-circle-edit-outline"
                          @click:append="edit('email')"
                      >
                      </v-text-field>
                  </v-tab-item>
                  <v-tab-item>
                    <v-subheader  >
                      <span v-if="confirmation">
                        <v-icon>mdi-cellphone-key</v-icon>
                        تأكيد رقم  الهاتف
                      </span>
                      <span v-else>
                          <v-icon>mdi-cellphone-message</v-icon>
                          تعديل ؤقم الهاتف
                      </span>
                      <v-spacer></v-spacer>
                      <v-btn small text color="success"  @click="update(null)" :loading="loading" id="verify-phone-button"> 
                        <v-icon v-if="!confirmation">mdi-check</v-icon>
                        <span v-else>أرسل الرمز مرة أخرى</span>
                      </v-btn>
                      <v-btn small text color="error" @click="edit(null)" :disabled="loading"> 
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-subheader>
                    <v-phone 
                      v-if="!confirmation"
                      @update="results = $event"
                      v-model="phoneNumber"
                      autofocus
                      ref="phoneNumber"
                    ></v-phone>
                      <v-text-field
                      v-else
                      autofocus
                          label="أدخل رمز التأكيد"
                          maxlength="6"
                          outlined
                          solo
                          class="code-input"
                          v-model="code"
                          @input="verifyCode"
                          :loading="codeLoading"
                          :rules="[ v => !!v || 'هذا الحقل مطلوب']"
                          ></v-text-field>

 
                    
                  </v-tab-item>
                  <v-tab-item>
                    <v-subheader>
                      <v-icon>mdi-lock-reset</v-icon>
                      أرسال رابط إلى البريد الإلكتروني لأعادة تعيين كلمة المرور؟
                      <v-spacer></v-spacer>
                      <v-btn small text color="success"  @click="update(null)" :loading="loading"> 
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn small text color="error" @click="edit(null)" :disabled="loading"> 
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-subheader>
                  </v-tab-item>
                </v-tabs-items>
              </v-form>         
            </v-card-text>
       
           
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-img>
   
</template>

<script>
export default {
  data () {
    return {
      codeLoading: false,
      verifier: null,
      code: null,
      phoneNumber: null,
      results: null,
      confirmation: null,
      loading: false,
      fieldToEdit: null,
      editTabs: [null, 'displayName', 'email', 'phoneNumber', 'password'],
      fields:[
        {
          label: 'الأسم الكامل',
          icon: 'mdi-label',
          field: 'displayName',
        },
         {
          label: 'البريد الإلكتروني',
          icon: 'mdi-email',
          field: 'email',
        },
        {
          label: 'رقم الهاتف',
          icon: 'mdi-cellphone',
          field: 'phoneNumber',
        }
      ]
    }
  },
  computed: {
    editTab () {
      const { fieldToEdit } = this
      return this.editTabs.indexOf(fieldToEdit)
    }
  },
  watch: {
    currentUser: {
      handler (user) {
        this.phoneNumber = user.phoneNumber
      },
      immediate: true
    },
    '$route': {
      handler (route) {
        if(route.query.edit)
          this.edit(route.query.edit)
      },
      immediate: true
    }
  },
  methods: {
    edit (field) {
      this.fieldToEdit = field
    },
    update () {
      const { fieldToEdit } = this
      const { form } = this.$refs
      if (!form.validate()) return

      const input = form.inputs.find(e => e.$attrs.name == fieldToEdit)
      const value = input ? input.lazyValue : null
      this.loading = true 
      switch (fieldToEdit) {
        case 'password':
          return this.resetPassword().then(() => this.edit(null)) 
        case 'displayName': 
          return this.updateDisplayName(value).then(() => this.edit(null)) 
        case 'email': 
          return this.updateEmailField(value).then(() => this.edit(null)) 
         case 'phoneNumber':{
           const { phoneNumber } = this.$refs
           const [ phoneInput ] = phoneNumber.$children
           if(!phoneInput.isValid) return this.loading = false

           return this.updatePhoneNumberField()
          
         } 
        default: {
          this.loading = false 
          this.edit(null)
          break;
        }
      }
    },
    change (src) {
      this.updateProfile({  photoURL: src })
          .then(() => {
          this.notify('تم تعديل الصورة الشخصية بنجاح.', { color: 'success', icon:'mdi-check-circle' })
      }).catch(this.handelError)
    },
    updatePhoneNumberField () {
      if (!this.results) return this.loading = false
      const { formattedNumber } = this.results

      if (!this.verifier)
        this.verifier = this.getRecaptchaVerifier('verify-phone-button') 
      
      this.sendPhoneNumberCode(formattedNumber, this.verifier).then( confirmation => {
          this.notify(`تم أرسال رمز التأكيد الى الرقم "${formattedNumber}" بنجاح`, { color: 'success', icon: 'mdi-check-circle' })
          this.confirmation = confirmation
          console.log(confirmation);

      }).catch(this.handelError).finally( () => {
           this.loading = false
          this.verifier.reset()
      })


     
      
    },
    verifyCode () {
        if(this.code.length != 6 && this.confirmation) return;
        this.codeLoading = true
        const credential = this.getPhoneCodeCredential(this.confirmation.verificationId, this.code);
        this.updatePhoneNumber(credential).then(() => {
             this.notify('تم تعديل رقم الهاتف بنجاح.', { color: 'success', icon:'mdi-check-circle' })
            this.confirmation = null
            this.edit(null)
        }).catch(this.handelError).finally(this.codeLoading = false)
    },
    updateEmailField (email) {
       if (!email) return 
      this.loading = true
      return this.updateEmail(email)
          .then(() => {
          this.notify('تم تعديل البريد الإلكتروني بنجاح.', { color: 'success', icon:'mdi-check-circle' })
      }).catch(this.handelError).finally(() => this.loading = false)
    },
    updateDisplayName (name) {
      if (!name) return 
      this.loading = true
      return this.updateProfile({  displayName: name })
          .then(() => {
          this.notify('تم تعديل الأسم بنجاح.', { color: 'success', icon:'mdi-check-circle' })
      }).catch(this.handelError).finally(() => this.loading = false)
    },
    async resetPassword () {
      this.loading = true
      const { email } = this.currentUser
      if (!email) return
      return this.sendPasswordResetEmail(email).then(() => {
        this.notify('تم أرسال رابط لأعادة تعيين كلمة المرور الى بريدك الالكتروني.', { color: 'success', icon:'mdi-check-circle' })
      }).catch(this.showError).finally(() => this.loading = false)
    },
  }
}
</script>

<style>

</style>